var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.receipt,
            title: _vm.title,
            center: "",
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.receipt = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.form, "label-width": "100px" },
                    },
                    [
                      _c("el-form-item", { attrs: { label: "剩余欠款：" } }, [
                        _vm._v(_vm._s(_vm.sum)),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: " 本次收款：", prop: "pay_data" } },
                        _vm._l(_vm.form.pay_data, function (item, index) {
                          return _c(
                            "el-col",
                            { key: index },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: { placeholder: "金额" },
                                model: {
                                  value: item.money,
                                  callback: function ($$v) {
                                    _vm.$set(item, "money", $$v)
                                  },
                                  expression: "item.money",
                                },
                              }),
                              _c(
                                "el-select",
                                {
                                  staticClass: "dwei",
                                  staticStyle: { width: "120px" },
                                  attrs: { placeholder: "请选择付款方式" },
                                  model: {
                                    value: item.pay_type,
                                    callback: function ($$v) {
                                      _vm.$set(item, "pay_type", $$v)
                                    },
                                    expression: "item.pay_type",
                                  },
                                },
                                _vm._l(_vm.account, function (list) {
                                  return _c("el-option", {
                                    key: list.id,
                                    attrs: { label: list.name, value: list.id },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "120px" },
                                  attrs: { placeholder: "收款人" },
                                  model: {
                                    value: item.user_id,
                                    callback: function ($$v) {
                                      _vm.$set(item, "user_id", $$v)
                                    },
                                    expression: "item.user_id",
                                  },
                                },
                                _vm._l(_vm.userdata, function (list) {
                                  return _c("el-option", {
                                    key: list.user_id,
                                    attrs: {
                                      label: list.user_name,
                                      value: list.user_id,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c("i", {
                                staticClass: "el-icon-circle-plus-outline",
                                staticStyle: { "font-size": "20px" },
                                on: { click: _vm.handleraddpay },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "抹零：", prop: "remark" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "201px" },
                            attrs: { placeholder: "抹零" },
                            model: {
                              value: _vm.form.zero_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "zero_amount", $$v)
                              },
                              expression: "form.zero_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注：", prop: "remark" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "201px" },
                            attrs: { placeholder: "备注" },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.receipt = !_vm.receipt
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }