<!--
 * @Author: 候怀烨
 * @Date: 2021-02-23 14:19:41
 * @LastEditTime: 2021-02-23 15:47:00
 * @LastEditors: Please set LastEditors
 * @Description: 批量清欠
 * @FilePath: \sd-vue-admin\src\views\project\finance\client\arrears\components\BatchSettlement.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="receipt"
      :title="title"
      center
      width="40%"
    >
      <div>
        <el-row>
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="清欠方式：" prop="clear_type">
              <el-select v-model="form.clear_type">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="欠款合计：">{{ sum }}</el-form-item>
            <el-form-item label=" 本次收款：" prop="pay_data">
              <el-col v-for="(item, index) in form.pay_data" :key="index">
                <el-input
                  v-model="item.money"
                  placeholder="金额"
                  style="width: 100px"
                ></el-input>
                <el-select
                  v-model="item.pay_type"
                  class="dwei"
                  placeholder="请选择收款方式"
                  style="width: 120px; margin: 0 10px"
                >
                  <el-option
                    v-for="list in account"
                    :key="list.id"
                    :label="list.name"
                    :value="list.id"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="item.user_id"
                  placeholder="收款人"
                  style="width: 120px"
                >
                  <el-option
                    v-for="list in userdata"
                    :key="list.user_id"
                    :label="list.user_name"
                    :value="list.user_id"
                  ></el-option>
                </el-select>
                <i
                  class="el-icon-circle-plus-outline"
                  style="font-size: 20px"
                  @click="handleraddpay"
                ></i>
              </el-col>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input
                v-model="form.remark"
                style="width: 201px"
                type="text"
                placeholder="备注"
              />
            </el-form-item>
          </el-form>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">确 定</el-button>
        <el-button @click="receipt = !receipt">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      // AddAttendance,
    },
    data() {
      return {
        receipt: false,
        title: '批量清欠',
        form: {
          id: '', //欠款单ID 如[1,2]
          pay_data: [{ pay_type: '', money: '', user_id: '' }], //收款数据 如: [{"pay_type":1,"money":500,"user_id":1},{"pay_type":1,"money":500,"user_id":2}]
          clear_type: 1, //清欠方式 1时间由远及近2由近及远
          remark: '',
        },
        sum: 0,
        userdata: [],
        account: [],
        url: {
          userlist: '/baseAdmin/common/user-list',
          account: '/promoteAdmin/deposit-order/pay-type',
          save: '/cashAdmin/cash/batch-clear',
        },
        options: [
          {
            id: 1,
            name: '时间由远及近',
          },
          {
            id: 2,
            name: '时间由近及远',
          },
        ],
      }
    },
    watch: {
      receipt(val) {
        if (val == false) {
          this.$refs['form'].resetFields()
          try {
          } catch (error) {}
        } else {
          this.form.pay_data[0].user_id = this.$store.getters['user/user_id']
        }
      },
    },
    mounted() {
      this.handleraccount()
      this.hanlderuserlist()
    },
    methods: {
      handleraddpay() {
        this.form.pay_data.push(
          JSON.parse(JSON.stringify({ pay_type: '', money: '', user_id: '' }))
        )
      },
      handlerData(row) {
        console.log(row, '选中数据')
        var ids = new Array()
        var sme = 0
        row.forEach((element) => {
          ids.push(element.id)
          // sme += Number(element.qian_money)
          sme = (sme * 10000 + Number(element.qian_money) * 10000) / 10000
        })
        console.log(sme, '')
        this.sum = sme
        this.form.pay_data[0].money = JSON.parse(JSON.stringify(this.sum))

        this.form.id = JSON.stringify(ids)
      },
      handlersave() {
        const a = JSON.parse(JSON.stringify(this.form))
        a.pay_data = JSON.stringify(a.pay_data)
        postAction(this.url.save, a)
          .then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.$emit('getlist')
              this.receipt = false
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      // 收款账户
      handleraccount() {
        postAction(this.url.account, { pageSize: -1, status: 1 })
          .then((res) => {
            console.log(res, '收款账户')
            this.account = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, { pageSize: -1 })
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style></style>
