var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.receipt,
            title: _vm.title,
            center: "",
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.receipt = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        inline: "",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单号：", prop: "bill_code" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { disabled: "", placeholder: "单号" },
                            model: {
                              value: _vm.form.bill_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "bill_code", $$v)
                              },
                              expression: "form.bill_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称：", prop: "cust_name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { disabled: "", placeholder: "客户名称" },
                            model: {
                              value: _vm.form.cust_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cust_name", $$v)
                              },
                              expression: "form.cust_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人：", prop: "boss" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { disabled: "", placeholder: "联系人" },
                            model: {
                              value: _vm.form.boss,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "boss", $$v)
                              },
                              expression: "form.boss",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话：", prop: "mobile" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { disabled: "", placeholder: "联系电话" },
                            model: {
                              value: _vm.form.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mobile", $$v)
                              },
                              expression: "form.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "原始单据：",
                            prop: "order_ori_code",
                          },
                        },
                        [
                          _vm.form.order_ori_code == "QICHUQIANKUAN"
                            ? _c(
                                "div",
                                [
                                  _c("el-input", {
                                    staticClass: "inputOrder",
                                    staticStyle: { width: "180px" },
                                    attrs: {
                                      disabled: "",
                                      placeholder: "原始单据",
                                    },
                                    model: {
                                      value: _vm.form.order_ori_code,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "order_ori_code",
                                          $$v
                                        )
                                      },
                                      expression: "form.order_ori_code",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("div", [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.handleCheckOrder.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "inputOrder",
                                      staticStyle: { width: "180px" },
                                      attrs: {
                                        disabled: "",
                                        placeholder: "原始单据",
                                      },
                                      model: {
                                        value: _vm.form.order_ori_code,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "order_ori_code",
                                            $$v
                                          )
                                        },
                                        expression: "form.order_ori_code",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注：", prop: "remark" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { disabled: "", placeholder: "备注" },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "欠款时间：", prop: "add_time" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { disabled: "", placeholder: "欠款时间" },
                            model: {
                              value: _vm.form.add_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "add_time", $$v)
                              },
                              expression: "form.add_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "初始欠款：",
                            prop: "ori_qian_money",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { disabled: "", placeholder: "初始欠款" },
                            model: {
                              value: _vm.form.ori_qian_money,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "ori_qian_money", $$v)
                              },
                              expression: "form.ori_qian_money",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "累计清欠：", prop: "qing_money" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { disabled: "", placeholder: "累计清欠" },
                            model: {
                              value: _vm.form.qing_money,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "qing_money", $$v)
                              },
                              expression: "form.qing_money",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "剩余欠款：", prop: "qian_money" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              disabled: "",
                              placeholder: "剩余欠款",
                              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                            },
                            model: {
                              value: _vm.form.qian_money,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "qian_money", $$v)
                              },
                              expression: "form.qian_money",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        { attrs: { stripe: "", data: _vm.listable } },
                        _vm._l(_vm.colemd, function (list, index) {
                          return _c("el-table-column", {
                            key: index,
                            attrs: {
                              label: list.label,
                              align: list.align,
                              prop: list.prop,
                              width: "",
                            },
                          })
                        }),
                        1
                      ),
                      _c("p", [_vm._v("抹零记录")]),
                      _c(
                        "el-table",
                        { attrs: { stripe: "", data: _vm.smalltable } },
                        _vm._l(_vm.smallcolemd, function (list, index) {
                          return _c("el-table-column", {
                            key: index,
                            attrs: {
                              label: list.label,
                              align: list.align,
                              prop: list.prop,
                              width: "",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("qingqian", { ref: "qing", on: { openlist: _vm.openlist } }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.form.bill_status != 3 && _vm.canClear == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlersave(_vm.row)
                        },
                      },
                    },
                    [_vm._v(" 清 欠 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.receipt = !_vm.receipt
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
          _c("sale-order", { ref: "saleOrder" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }