var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "", model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "depart_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择部门" },
                      model: {
                        value: _vm.form.depart_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depart_id", $$v)
                        },
                        expression: "form.depart_id",
                      },
                    },
                    _vm._l(_vm.department, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "user_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择业务员" },
                      model: {
                        value: _vm.form.user_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "user_id", $$v)
                        },
                        expression: "form.user_id",
                      },
                    },
                    _vm._l(_vm.supplier, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.user_name, value: item.user_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "bill_status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "状态", clearable: "" },
                      model: {
                        value: _vm.form.bill_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "bill_status", $$v)
                        },
                        expression: "form.bill_status",
                      },
                    },
                    _vm._l(_vm.Customertype, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "print_status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "打印状态" },
                      model: {
                        value: _vm.form.print_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "print_status", $$v)
                        },
                        expression: "form.print_status",
                      },
                    },
                    _vm._l(_vm.Typesof, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "print_status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "是否核对" },
                      model: {
                        value: _vm.form.is_check,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_check", $$v)
                        },
                        expression: "form.is_check",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "全部", value: "0" } }),
                      _c("el-option", {
                        attrs: { label: "已核对", value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: "未核对", value: "2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "Time" } },
                [
                  _c(
                    "el-date-picker",
                    {
                      attrs: {
                        type: "datetimerange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        "default-time": ["00:00:00", "23:59:59"],
                      },
                      model: {
                        value: _vm.form.Time,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "Time", $$v)
                        },
                        expression: "form.Time",
                      },
                    },
                    [_vm._v(" > ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "cust_area_id" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "140px" },
                    attrs: {
                      options: _vm.areaList,
                      placeholder: "选择区域",
                      props: {
                        checkStrictly: true,
                        value: "id",
                        label: "name",
                        emitPath: false,
                      },
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.cust_area_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cust_area_id", $$v)
                      },
                      expression: "form.cust_area_id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "keyword" } },
                [
                  _c("client-search", {
                    ref: "clientSearch",
                    attrs: { "popper-class": "selectDC" },
                    on: {
                      "select-val-alldata": _vm.setClientKeyword,
                      "select-val-input": _vm.custSelectv,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlersver },
                    },
                    [_vm._v("查 询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: { click: _vm.handlerReset },
                    },
                    [_vm._v("重 置")]
                  ),
                  _c("el-button", { on: { click: _vm.handleExport } }, [
                    _vm._v("导 出"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              ref: "table",
              attrs: {
                stripe: "",
                data: _vm.tableData,
                "row-key": _vm.getRowKey,
                "show-summary": "",
                "summary-method": _vm.summaryFunction,
              },
              on: { "selection-change": _vm.handlerSelection },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", "reserve-selection": true },
              }),
              _c(
                "el-table-column",
                {
                  attrs: { align: "center", width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          row.bill_code == "合计"
                            ? _c("div")
                            : _c("div", [_vm._v(_vm._s($index + 1))]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.colemd, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._l(_vm.finallyColumns, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            list.label == "原始单号"
                              ? _c("div", [
                                  row.order_ori_type == 1
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-decoration": "underline",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCheckOrder(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row[list.prop]) + " "
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row[list.prop])),
                                      ]),
                                ])
                              : list.label == "已核对"
                              ? _c(
                                  "div",
                                  [
                                    _c("el-checkbox", {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handlercheck(
                                            $event,
                                            row.id
                                          )
                                        },
                                      },
                                      model: {
                                        value: row[list.prop],
                                        callback: function ($$v) {
                                          _vm.$set(row, list.prop, $$v)
                                        },
                                        expression: "row[list.prop]",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v(_vm._s(row[list.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  fixed: "right",
                  width: "120px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerlink(row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确定要设为坏账吗？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.handlerstorp(row)
                              },
                            },
                          },
                          [
                            row.bill_status_text != "已结清"
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.bill_status != 5,
                                        expression: "row.bill_status != 5",
                                      },
                                    ],
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 坏账 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleprint(row)
                              },
                            },
                          },
                          [_vm._v(" 打印 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex" } },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.can_clear == 1,
                  expression: "can_clear == 1",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.handlerBatch },
            },
            [_vm._v(" 批量清欠 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.handlerBatchChangeStaff },
            },
            [_vm._v(" 更换业务员 ")]
          ),
        ],
        1
      ),
      _c("link-arrears", {
        ref: "add",
        attrs: { "can-clear": _vm.can_clear },
        on: { getlist: _vm.getlist, refresh: _vm.handlersver },
      }),
      _c("batch-settlement", { ref: "batch", on: { getlist: _vm.getlist } }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "YS", data_id: _vm.data_id, type: 1 },
      }),
      _c("sale-order", { ref: "saleOrder" }),
      _c("ChangeStaff", { ref: "changeStaff", on: { refresh: _vm.getlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }