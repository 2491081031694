<!--
 * @Author: 候怀烨
 * @Date: 2021-02-23 14:13:37
 * @LastEditTime: 2021-02-23 16:48:26
 * @LastEditors: Please set LastEditors
 * @Description: 查看
 * @FilePath: \sd-vue-admin\src\views\project\finance\client\arrears\components\LinkArrears.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="receipt"
      :title="title"
      center
      width="40%"
    >
      <div>
        <el-row>
          <el-form ref="form" :model="form" inline label-width="100px">
            <el-form-item label="单号：" prop="bill_code">
              <el-input
                v-model="form.bill_code"
                disabled
                placeholder="单号"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item label="客户名称：" prop="cust_name">
              <el-input
                v-model="form.cust_name"
                disabled
                placeholder="客户名称"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item label="联系人：" prop="boss">
              <el-input
                v-model="form.boss"
                disabled
                placeholder="联系人"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item label="联系电话：" prop="mobile">
              <el-input
                v-model="form.mobile"
                disabled
                placeholder="联系电话"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item label="原始单据：" prop="order_ori_code">
              <div v-if="form.order_ori_code == 'QICHUQIANKUAN'">
                <el-input
                  v-model="form.order_ori_code"
                  class="inputOrder"
                  disabled
                  placeholder="原始单据"
                  style="width: 180px"
                />
              </div>
              <div v-else>
                <div @click.prevent="handleCheckOrder">
                  <el-input
                    v-model="form.order_ori_code"
                    class="inputOrder"
                    disabled
                    placeholder="原始单据"
                    style="width: 180px"
                  />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input
                v-model="form.remark"
                disabled
                placeholder="备注"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item label="欠款时间：" prop="add_time">
              <el-input
                v-model="form.add_time"
                disabled
                placeholder="欠款时间"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item label="初始欠款：" prop="ori_qian_money">
              <el-input
                v-model="form.ori_qian_money"
                disabled
                placeholder="初始欠款"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item label="累计清欠：" prop="qing_money">
              <el-input
                v-model="form.qing_money"
                disabled
                placeholder="累计清欠"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item label="剩余欠款：" prop="qian_money">
              <el-input
                v-model="form.qian_money"
                disabled
                placeholder="剩余欠款"
                onkeyup="value=value.replace(/[^\d.]/g,'') "
                style="width: 180px"
              />
            </el-form-item>
            <el-table stripe :data="listable">
              <el-table-column
                v-for="(list, index) in colemd"
                :key="index"
                :label="list.label"
                :align="list.align"
                :prop="list.prop"
                width=""
              ></el-table-column>
            </el-table>
            <p>抹零记录</p>
            <el-table stripe :data="smalltable">
              <el-table-column
                v-for="(list, index) in smallcolemd"
                :key="index"
                :label="list.label"
                :align="list.align"
                :prop="list.prop"
                width=""
              ></el-table-column>
            </el-table>
          </el-form>
        </el-row>
        <qingqian ref="qing" @openlist="openlist"></qingqian>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- v-show="form.qian_money > 0" -->
        <el-button
          v-if="form.bill_status != 3 && canClear == 1"
          type="primary"
          @click="handlersave(row)"
        >
          清 欠
        </el-button>
        <!--        <el-button
          v-if="form.bill_status != 3"
          type="warning"
          @click="handleMoLing(row)"
        >
          抹 零
        </el-button>-->
        <el-button @click="receipt = !receipt">取 消</el-button>
      </div>
      <sale-order ref="saleOrder"></sale-order>
    </el-dialog>
  </div>
</template>

<script>
  import qingqian from './qingqian.vue'
  import { postAction } from '@/api/Employee'
  import saleOrder from '@/views/project/sale/bills/saleOrder/components/checkOrder'
  export default {
    components: {
      qingqian,
      saleOrder,
    },
    props: {
      canClear: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        receipt: false,
        title: '应收欠款',
        form: {
          id: '',
          cust_id: '',
          cust_name: '',
          boss: '',
          mobile: '',
          bill_code: '',
          total_amount: '',
          ori_qian_money: '',
          qing_money: '',
          qian_money: '',
          order_ori_type: '',
          order_ori_id: '',
          order_ori_code: '',
          bill_status: '',
          bill_status_text: '',
          add_name: '',
          add_time: '',
        },
        row: {},
        listable: [],
        smalltable: [],
        url: {
          ress: '/setAdmin/attendance/address-list',
          save: '/setAdmin/attendance/save',
          info: '/cashAdmin/cash/arrears-detail',
        },
        smallcolemd: [
          {
            order: 1,
            label: '抹零金额',
            align: 'center',
            prop: 'zero_amount',
            width: '',
          },
          {
            order: 2,
            label: '操作人',
            align: 'center',
            prop: 'user_name',
            width: '',
          },
          {
            order: 3,
            label: '抹零时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
        ],
        colemd: [
          {
            order: 1,
            label: '收款方式',
            align: 'center',
            prop: 'name',
            width: '',
          },
          {
            order: 2,
            label: '金额',
            align: 'center',
            prop: 'bill_amount',
            width: '',
          },
          {
            order: 3,
            label: '清欠人',
            align: 'center',
            prop: 'add_name',
            width: '',
          },
          {
            order: 4,
            label: '清欠时间',
            align: 'center',
            prop: 'add_time',
            width: '',
          },
          {
            order: 5,
            label: '备注',
            align: 'center',
            prop: 'remark',
            width: '',
          },
        ],
      }
    },
    watch: {
      receipt(val) {
        if (val == false) {
          this.$refs['form'].resetFields()
          try {
            delete this.form.id
          } catch (error) {}
          console.log(this.form)
        }
      },
    },
    mounted() {},
    methods: {
      openlist() {
        this.$emit('getlist')
        this.receipt = false
      },
      handlerData(row) {
        this.row = row
        postAction(this.url.info, { id: row.id })
          .then((res) => {
            console.log(res)
            this.form = res.data.info
            this.listable = res.data.list
            this.smalltable = res.data.zero_list
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlersave(row) {
        this.$refs.qing.receipt = true
        this.$refs.qing.handlerData(row)
        //   postAction(this.url.save, a)
        //     .then((res) => {
        //       console.log(res)
        //       if (res.code == 200) {
        //         this.$message({
        //           message: res.msg,
        //           type: 'success',
        //         })
        //         this.$emit('getlist')
        //         this.receipt = false
        //       }
        //     })
        //     .catch((err) => {
        //       console.log(err)
        //     })
        // },
      },
      // 查看销售单
      handleCheckOrder() {
        let id = this.row.order_ori_id
        console.log('id', id)
        this.$refs.saleOrder.id = id
        this.$refs.saleOrder.isshowDialog = true
      },
      handleMoLing(row) {
        this.$confirm(
          '抹零后该笔欠款将变为已结清状态，不可继续清欠！抹零金额将计为原销售单中优惠金额，应收款相应减少。',
          '确定要抹零吗?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback: (c) => {
              if (c == 'confirm') {
                postAction('/cashAdmin/cash/arrear-zero', { id: row.id }).then(
                  (res) => {
                    this.$message.success('抹零成功')
                    // this.handlerData(row)
                    this.$emit('refresh')
                    this.receipt = false
                  }
                )
              }
            },
          }
        )
      },
    },
  }
</script>

<style scoped lang="scss">
  .inputOrder {
    ::v-deep input {
      text-decoration: underline;
      cursor: pointer !important;
    }
  }
</style>
