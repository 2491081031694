<!--
 * @Author: your name
 * @Date: 2021-02-07 15:12:23
 * @LastEditTime: 2021-02-23 16:53:34
 * @LastEditors: Please set LastEditors
 * @Description: 应收欠款
 * @FilePath: \sd-vue-admin\src\views\project\finance\client\arrears\index.vue
-->
<template>
  <!-- NAME[epic=财务] 应收欠款 -->
  <div style="padding: 20px">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="depart_id">
          <el-select
            v-model="form.depart_id"
            placeholder="请选择部门"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in department"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="user_id">
          <el-select
            v-model="form.user_id"
            placeholder="请选择业务员"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in supplier"
              :key="index"
              :label="item.user_name"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="bill_status">
          <el-select
            v-model="form.bill_status"
            placeholder="状态"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Customertype"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="print_status">
          <el-select
            v-model="form.print_status"
            placeholder="打印状态"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Typesof"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="print_status">
          <el-select
            v-model="form.is_check"
            placeholder="是否核对"
            style="width: 120px"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option label="已核对" value="1"></el-option>
            <el-option label="未核对" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="Time">
          <el-date-picker
            v-model="form.Time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="cust_area_id">
          <el-cascader
            v-model="form.cust_area_id"
            :options="areaList"
            placeholder="选择区域"
            :props="{
              checkStrictly: true,
              value: 'id',
              label: 'name',
              emitPath: false,
            }"
            clearable
            style="width: 140px"
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="keyword">
          <!-- <el-input
            v-model="form.keyword"
            placeholder="客户名称、简拼、电话"
          ></el-input> -->
          <client-search
            ref="clientSearch"
            :popper-class="'selectDC'"
            @select-val-alldata="setClientKeyword"
            @select-val-input="custSelectv"
          ></client-search>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlersver">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <el-button @click="handleExport">导 出</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        ref="table"
        stripe
        :data="tableData"
        :row-key="getRowKey"
        show-summary
        :summary-method="summaryFunction"
        @selection-change="handlerSelection"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column align="center" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in colemd"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
          <template #default="{ row, $index }">
            <div v-if="row.bill_code == '合计'"></div>
            <div v-else>{{ $index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(list, index) in finallyColumns"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template #default="{ row }">
            <div v-if="list.label == '原始单号'">
              <span
                v-if="row.order_ori_type == 1"
                style="text-decoration: underline; cursor: pointer"
                @click="handleCheckOrder(row)"
              >
                {{ row[list.prop] }}
              </span>
              <span v-else>{{ row[list.prop] }}</span>
            </div>
            <div v-else-if="list.label == '已核对'">
              <el-checkbox
                v-model="row[list.prop]"
                :true-label="1"
                :false-label="0"
                @change="handlercheck($event, row.id)"
              ></el-checkbox>
            </div>
            <div v-else>{{ row[list.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          fixed="right"
          width="120px"
        >
          <template #default="{ row }">
            <el-button type="text" @click="handlerlink(row)">查看</el-button>
            <el-popconfirm
              title="确定要设为坏账吗？"
              @confirm="handlerstorp(row)"
            >
              <el-button
                v-show="row.bill_status != 5"
                v-if="row.bill_status_text != '已结清'"
                slot="reference"
                style="margin-left: 10px"
                type="text"
              >
                坏账
              </el-button>
            </el-popconfirm>
            <el-button
              style="margin-left: 10px"
              type="text"
              @click="handleprint(row)"
            >
              打印
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <el-row type="flex" class="row-bg">
      <el-button v-show="can_clear == 1" type="primary" @click="handlerBatch">
        批量清欠
      </el-button>
      <el-button type="primary" @click="handlerBatchChangeStaff">
        更换业务员
      </el-button>
    </el-row>
    <link-arrears
      ref="add"
      :can-clear="can_clear"
      @getlist="getlist"
      @refresh="handlersver"
    ></link-arrears>
    <batch-settlement ref="batch" @getlist="getlist"></batch-settlement>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="YS"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <sale-order ref="saleOrder"></sale-order>
    <ChangeStaff ref="changeStaff" @refresh="getlist"></ChangeStaff>
  </div>
</template>

<script>
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import LinkArrears from './components/LinkArrears.vue'
  import BatchSettlement from './components/BatchSettlement.vue'
  import timefort from '@/utils/timestamp'
  import { postAction } from '@/api/Employee'
  import saleOrder from '@/views/project/sale/bills/saleOrder/components/checkOrder'
  import { downloadFile } from '@/api/Employee'
  import ChangeStaff from './components/changeStaff.vue'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { getSelect } from '@/api/saleOrder'

  export default {
    components: {
      LinkArrears,
      BatchSettlement,
      RuilangDisplayDialog,
      saleOrder,
      ChangeStaff,
      ClientSearch,
    },
    data() {
      return {
        data_id: 0,
        can_clear: '',
        form: {
          depart_id: '', //部门ID
          user_id: '', //员工ID
          bill_status: '', //单据状态  1未收款2部分结款3已结清5坏账
          print_status: '', //打印状态  1已打印 2未打印
          Time: [],
          start_time: '', //开始时间
          end_time: '', //结束时间
          keyword: '', //店铺名称 简拼
          pageNo: 1,
          pageSize: 10,
          cust_id: '', //客户ID
        },
        total: 0,
        areaList: [],
        Typesof: [
          {
            id: '0',
            name: '未打印',
          },
          {
            id: '1',
            name: '已打印',
          },
        ],
        //1未收款2部分结款3已结清5坏账
        Customertype: [
          {
            id: '1',
            name: '未收款',
          },
          {
            id: '2',
            name: '部分结款',
          },
          {
            id: '3',
            name: '已结清',
          },
          {
            id: '5',
            name: '坏账',
          },
        ],
        department: [],
        supplier: [],
        url: {
          department: '/baseAdmin/depart/index',
          list: '/cashAdmin/cash/arrears-list',
          supplier: '/baseAdmin/common/user-list-auth',
          stop: '/cashAdmin/cash/arrears-bad',
        },
        tableData: [],
        checkList: [
          '部门',
          '业务员',
          '客户名称',
          '欠款单号',
          '原始单号',
          '下单时间',
          '欠款时间',
          '已清欠',
          '初始欠款',
          '抹零',
          '坏账金额',
          '已核对',
          '结清/坏账时间',
          '剩余欠款',
          '状态',
          '单据备注',
          '清欠备注',
        ],
        colemd: [
          {
            order: 1,
            label: '部门',
            align: 'center',
            prop: 'depart_name',
            width: '',
          },
          {
            order: 2,
            label: '业务员',
            align: 'center',
            prop: 'create_name',
            width: '',
          },
          {
            order: 3,
            label: '客户名称',
            align: 'center',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 4,
            label: '欠款单号',
            align: 'center',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 5,
            label: '原始单号',
            align: 'center',
            prop: 'order_ori_code',
            width: '',
          },
          {
            order: 5.5,
            label: '下单时间',
            align: 'center',
            prop: 'order_ori_time',
            width: '',
          },
          {
            order: 6,
            label: '欠款时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },

          {
            order: 7,
            label: '初始欠款',
            align: 'center',
            prop: 'ori_qian_money',
            width: '',
          },
          {
            order: 7.1,
            label: '已清欠',
            align: 'center',
            prop: 'qing_money',
            width: '',
          },
          {
            order: 7.2,
            label: '抹零',
            align: 'center',
            prop: 'zero_money',
            width: '',
          },
          {
            order: 8,
            label: '剩余欠款',
            align: 'center',
            prop: 'qian_money',
            width: '',
          },
          {
            order: 8.2,
            label: '坏账金额',
            align: 'center',
            prop: 'bad_money',
            width: '',
          },
          {
            order: 8.5,
            label: '已核对',
            align: 'center',
            prop: 'is_check',
            width: '',
          },
          {
            order: 9,
            label: '状态',
            align: 'center',
            prop: 'bill_status_text',
            width: '',
          },
          {
            order: 9.1,
            label: '结清/坏账时间',
            align: 'center',
            prop: 'qing_bad_time',
            width: '',
          },
          {
            order: 10,
            label: '单据备注',
            align: 'center',
            prop: 'remark',
            width: '',
          },
          {
            order: 11,
            label: '清欠备注',
            align: 'center',
            prop: 'last_remark',
            width: '',
          },
        ],
        footer: {},
        sum: [],
        tableSum: null,
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.colemd.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      tableData(val) {
        this.$refs.table.clearSelection()
      },
      'form.Time'(val) {
        console.log(val)
        try {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
          console.log(this.form.start_time)
        } catch (error) {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      'form.goodsname'(val) {
        if (val == '') {
          this.form.goods_id = ''
        }
      },
    },
    mounted() {
      this.handlerlist()
      this.handlersupplier()
      this.handlerdepartment()
      getSelect().then((res) => {
        this.areaList = res.data.cust_area //区域
      })
    },
    methods: {
      handlercheck(e, id) {
        console.log(e, id)
        postAction('cashAdmin/cash/check', { id: id, status: e }).then(
          (res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerlist()
          }
        )
      },
      handlerBatch() {
        if (this.sum.length > 0) {
          this.$refs.batch.receipt = true
          this.$refs.batch.handlerData(this.sum)
        } else {
          this.$message({
            message: '请先选择要清欠的数据',
            type: 'info',
          })
        }
      },
      handlerBatchChangeStaff() {
        if (this.sum.length > 0) {
          let ids = []
          this.sum.forEach((i) => {
            if (i.id) ids.push(i.id)
          })
          this.$refs.changeStaff.type = 3
          this.$refs.changeStaff.ids = ids
          this.$refs.changeStaff.showDialog = true
        } else {
          this.$message({
            message: '请勾选单据',
            type: 'info',
          })
        }
      },
      handlerlink(row) {
        this.$refs.add.receipt = true
        this.$refs.add.showpay = '2'
        this.$refs.add.handlerData(row)
      },
      handlerPay(row) {
        this.$refs.add.receipt = true
        this.$refs.add.showpay = '1'
        this.$refs.add.handlerData(row)
      },
      getlist() {
        this.handlerlist()
      },
      handlerSelection(val) {
        console.log(val, '选完后的')
        this.sum = val
      },
      getRowKey(row) {
        return row.id
      },
      //部门
      handlerdepartment() {
        postAction(this.url.department, { pageSize: -1, is_close: 0 })
          .then((res) => {
            console.log('部门', res)
            this.department = res.data.list
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //业务员
      handlersupplier() {
        postAction(this.url.supplier, {})
          .then((res) => {
            console.log(res, '业务员')
            this.supplier = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },

      handlerstorp(row) {
        postAction(this.url.stop, { id: row.id }).then((res) => {
          console.log(res, '坏账')
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.handlerlist()
        })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlersver() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      // 表格合计fun
      summaryFunction(param) {
        // const { columns, data } = param
        // // 需要合计的下标
        // let i = [8, 9]
        let sums = []
        sums[0] = '总计'
        if (this.tableSum) {
          sums[9] = this.tableSum.ori_qian_money
          sums[10] = this.tableSum.qing_money
          sums[11] = this.tableSum.zero_money
          sums[12] = this.tableSum.qian_money
          sums[13] = this.tableSum.bad_money
        }
        // // let val = data.map((item) => {
        // //   console.log(item)
        // // })
        // columns.forEach((col, idx) => {
        //   i.forEach((n) => {
        //     if (idx == n) {
        //       console.log(col.property)
        //       const val = data.map((item) => Number(item[col.property]))
        //       console.log(val)
        //       if (!val.every((value) => isNaN(value))) {
        //         const a = val.reduce((total, num) => {
        //           if (!isNaN(total)) {
        //             return total + num
        //           }
        //         })
        //         sums[n] = a.toFixed(2) + ' 元'
        //       }
        //     }
        //   })
        //   if (idx == 0) {
        //     sums[idx] = '合计'
        //   }
        // })
        // console.log('sss', sums)
        return sums
      },
      setClientKeyword(val) {
        console.log('val=====', val)

        if (val.id) {
          this.form.cust_id = val.id
        } else {
          this.form.cust_id = ''
        }
      },
      custSelectv(val) {
        this.form.cust_name = val
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.handlerlist()
      },
      handleprint(row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      handlerlist() {
        var data = JSON.parse(JSON.stringify(this.form))
        delete data.Time
        delete data.goodsname
        postAction(this.url.list, data)
          .then((res) => {
            this.can_clear = res.data.can_qing
            console.log(res, 'tablelie')
            this.tableData = res.data.rows
            this.tableSum = res.data.sum
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleCheckOrder(row) {
        this.$refs.saleOrder.id = row.order_ori_id
        this.$refs.saleOrder.isshowDialog = true
      },
      // 导出
      handleExport() {
        downloadFile(
          '/cashAdmin/cash/arrears-list-export',
          '应收欠款.xlsx',
          this.form
        )
      },
    },
  }
</script>

<style></style>
